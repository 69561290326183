import $interfaces from '@/axios/interfaces';
import Element from 'element-ui';
/**
 * 用认证中心返回的 code 传给我们自己的后端换取 token
 * @param {Object} to 路由守卫的 to
 * @param {Function} next 路由守卫的 next
 */
export default async (code, to, next) => {
  const loading = Element.Loading.service({ text: '加载中...' });
  const { resp_code, data, resp_msg } = await $interfaces.oauth.accessToken({ code });

  if (resp_code === 0) {
    // 防止后端返回缓存的 permissions
    // data.user.permissions = null;
    const accessToken = data;

    localStorage.setItem('token', accessToken);
    // localStorage.setItem('user', JSON.stringify(user));

    next(to.path);
    loading.close();
  } else {
    let nextPage = document.createElement('a');
    nextPage.setAttribute('href', data);
    Element.Message.error(resp_msg);
    setTimeout(() => {
      nextPage.click();
    }, 3000);
  }
};
